body {
  color: #fff;
  letter-spacing: .0125em;
  text-transform: lowercase;
  background-color: #000;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 7vw;
}

#root {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  padding: .75em 1em;
}

p {
  margin: 1em auto;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

p > span {
  width: 1em;
  text-align: center;
  display: inline-block;
}

/*# sourceMappingURL=index.a103babf.css.map */
